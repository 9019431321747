
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import Script from "next/script";
import { AppProps } from "next/app";
import { useAuthInit, useAuthNewInit, useInitialNewLogin, } from "modules/Auth/authHooks";
import "../styles/globals.css";
import localFont from "@next/font/local";
import { DefaultMeta } from "modules/Meta/DefaultMeta";
import { ApiContext } from "lib/apiFetcher";
import NextNProgress from "nextjs-progressbar";
import { ToastWrapper } from "components/Toast/ToastWrapper";
import { AlgoliaContext } from "lib/algolia";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";
import { useSetUserAmplitudeWhenAuthChange } from "lib/amplitude";
import { setCookie, getCookie, getQueryStringParams, setParamsAsCookies, } from "lib/helper";
import { useAuthStore } from "modules/Auth/authStore";
import GoogleAnalytics from "modules/GoogleAnalytics/GoogleAnalytics";
import { NextPageContext } from "next";
import { IsDesktopContext, isDeviceDesktop } from "lib/device";
import { IsBusinessContext, IsTenantContext, IsGateContext, isBusinessURL, isGateURL, isTenantURL, } from "lib/host";
import { IsGlobalContext } from "lib/global";
const CircularFont = localFont({
    src: [
        { path: "../fonts/circular-light.woff2", weight: "300" },
        { path: "../fonts/circular-medium.woff2", weight: "500" },
        { path: "../fonts/circular-black.woff2", weight: "700" },
    ],
});
const MyApp = ({ Component, pageProps, router }: AppProps) => {
    useAuthNewInit();
    useSetUserAmplitudeWhenAuthChange();
    // ========== Version ========== //
    useEffect(() => {
        if (pageProps?.state?.version) {
            localStorage.setItem("gk-version", pageProps?.state?.version);
        }
    });
    // ========== Version ========== //
    return (<>
      <NextNProgress color="#551FC1" options={{
            showSpinner: false,
        }} transformCSS={(css) => {
            return <style>{css.replaceAll("\n", "")}</style>;
        }}/>
      <div className={[CircularFont.className, "text-purple-80 font-medium"].join(" ")}>
        <IsGlobalContext.Provider value={{ ...pageProps?.state }}>
          <IsDesktopContext.Provider value={pageProps?.isDesktop}>
            <IsTenantContext.Provider value={pageProps?.isTenant}>
              <IsBusinessContext.Provider value={pageProps?.isBusiness}>
                <IsGateContext.Provider value={pageProps?.isGate}>
                  <ApiContext.Provider value={{ __apiData: pageProps?.__apiData || {} }}>
                    <AlgoliaContext.Provider value={{ __algoliaData: pageProps?.__algoliaData || {} }}>
                      <DefaultMeta withDefault={true}/>
                      <Component {...pageProps}/>
                      <ToastWrapper />
                    </AlgoliaContext.Provider>
                  </ApiContext.Provider>
                </IsGateContext.Provider>
              </IsBusinessContext.Provider>
            </IsTenantContext.Provider>
          </IsDesktopContext.Provider>
        </IsGlobalContext.Provider>
      </div>
    </>);
};
MyApp.getInitialProps = ({ ctx }: {
    ctx: NextPageContext;
}) => {
    let isDesktop = false;
    let isTenant = false;
    let isBusiness = false;
    let isGate = false;
    try {
        if (ctx.req) {
            // console.log({ ua: ctx.req.headers["user-agent"] });
            isDesktop = isDeviceDesktop(ctx.req?.headers?.["user-agent"]);
            isTenant = isTenantURL(ctx.req?.headers?.["host"]);
            isBusiness = isBusinessURL(ctx.req?.headers?.["host"]);
            isGate = isGateURL(ctx.req?.headers?.["host"]);
        }
        else {
            isDesktop = isDeviceDesktop(window.navigator.userAgent);
            isTenant = isTenantURL(window.location.hostname);
            isBusiness = isBusinessURL(window.location.hostname);
            isGate = isGateURL(window.location.hostname);
        }
    }
    catch { }
    return {
        pageProps: {
            isDesktop,
            isTenant,
            isBusiness,
            isGate,
            // add version website: flow login has been updated with lclStorage & cookies (for oauth) - 15/09/2023
            // users who already login, should force logout based new flow (so, users can open AWS courses)
            // this global state is used to store all global context, can update by state or pageProps
            state: { version: "v2.3.6" },
        },
    };
};
const __Next_Translate__Page__1932e99cf61__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1932e99cf61__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  